<template>
	<div class="portcallclearancesstatusFormHeader puiformheader">
		<!--<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.portcallclearancesstatus.portcallid')" :value="getPortcallidValue"></pui-field>
			</v-col>
			<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.portcallclearancesstatus.arrcustomsstatus')" :value="getArrcustomsstatusValue"></pui-field>
			</v-col>

			<v-col cols="12">
				<pui-field :label="$t('header.portcallclearancesstatus.voyagenum')" :value="getVoyagenumValue"></pui-field>
			</v-col>
		</v-row>
		</v-row>-->
		<v-row>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.visitid')" :value="getVisitidValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.portcalls.porttra')" :value="getPcportdescValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.portcalls.shipname')" :value="getShipnameValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.portcalls.pcstatus')" :value="getPcstatusdescValue"></pui-field>
			</v-col>
		</v-row>

		<v-row>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.arrimmigrationstatusdesc')" :value="getArrimmigrationstatusValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.arrcustomsstatusdesc')" :value="getArrcustomsstatusValue2"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.arrharbourstatusdesc')" :value="getArrharbourstatusValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.depimmigrationstatusdesc')" :value="getDepimmigrationstatusValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.depcustomsstatusdesc')" :value="getDepcustomsstatusValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.pcclearancesstatus.depharbourstatusdesc')" :value="getDepharbourstatusValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'portcallclearancesstatus-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'portcallclearancesstatus'
		};
	},
	computed: {
		getPortcallidValue() {
			return this.model && this.model.portcallid ? this.model.portcallid : '-';
		},
		getVisitidValue() {
			return this.model && this.model.visitid ? this.model.visitid : '-';
		},
		getPcportdescValue() {
			return this.model && this.model.pcportdesc ? this.model.pcportdesc : '-';
		},
		getShipnameValue() {
			return this.model && this.model.shipname ? this.model.shipname : '-';
		},
		getPcstatusdescValue() {
			return this.model && this.model.pcstatusdesc ? this.model.pcstatusdesc : '-';
		},

		getArrimmigrationstatusValue() {
			return this.model && this.model.arrimmigrationstatusdesc ? this.model.arrimmigrationstatusdesc : '-';
		},
		getArrcustomsstatusValue() {
			return this.model && this.model.arrcustomsstatusdesc ? this.model.arrcustomsstatusdesc : '-';
		},
		getArrharbourstatusValue() {
			return this.model && this.model.arrharbourstatusdesc ? this.model.arrharbourstatusdesc : '-';
		},
		getArrcustomsstatusValue2() {
			return this.model && this.model.arrcustomsstatusdesc ? this.model.arrcustomsstatusdesc : '-';
		},

		getDepimmigrationstatusValue() {
			return this.model && this.model.depimmigrationstatusdesc ? this.model.depimmigrationstatusdesc : '-';
		},
		getDepcustomsstatusValue() {
			return this.model && this.model.depcustomsstatusdesc ? this.model.depcustomsstatusdesc : '-';
		},
		getDepharbourstatusValue() {
			return this.model && this.model.depharbourstatusdesc ? this.model.depharbourstatusdesc : '-';
		},
		getVoyagenumValue() {
			return this.model && this.model.voyagenum ? this.model.voyagenum : '-';
		}
	}
};
</script>
